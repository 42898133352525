.wrapper {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.card {
  border: 1px solid rgb(45, 43, 43);
  box-shadow: 3px 3px 2px 0px gray;
  max-width: 500px;
  border-radius: 3px;
  margin: 10px;
  padding: 15px;
  display: flex;
  align-items: center;

  flex-direction: column;

}

.getit {
  width: 50%;

}

.ul {
  text-align: left;
}


@media screen and (max-width: 768px) {
  .cards {
    flex-direction: column;
  }
}