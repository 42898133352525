.wrapMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 75vh;
  margin-bottom: 100px;
  width: 100%;
}

.getTokens {
  border: none;
  background-color: transparent;
  padding: 7px;
  padding-bottom: 3px;
  margin: 2px;
  color: #1CB5E0;
  font-size: 14px;
  /* text-decoration: underline; */
  border: 1px solid transparent;
  /* border-bottom: 0.1px solid #1CB5E0; */
  transition: all 0.5s ease;
}


.getTokens:hover {
  padding: 7px;
  margin-bottom: 1px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #1CB5E0;
  box-shadow: 0px 0px 3px 3px #1CB5E0;
}

.confirmBut {
  width: 100%;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 5px;
  max-width: 750px;
  box-sizing: border-box;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  /* background-color: rgb(0, 0, 0); */
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* background-color: #fefefe; */
  margin: 50px 0px 0px 0px;
  /* 15% from the top and centered */
  /* padding: 20px; */
  border: 1px solid #888;
}

.partOne {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid rgb(0, 115, 255); */
  border-radius: 18px;
  padding: 10px;
  box-sizing: border-box;
}

.currentImage {
  width: 350px;
  height: auto;
}

.labelInput {
  display: flex;
  width: 100%;
  align-items: center;
}

.inputImageUrl {
  padding: 10px;
  margin: 5px;
  border-radius: 15px;
  min-width: 200px;
  flex: 1 0 31%;
  max-width: 90%;
  box-sizing: border-box;
  border: 1px solid rgb(0, 132, 255);
}


.number {
  background-color: rgb(0, 168, 168);
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 15px;
  border-radius: 100px;
  color: white;
  font-weight: bolder;
  border: 3px solid white;

  box-shadow: 0px 0px 0px 2px rgb(0, 164, 200);

}

.parts {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid rgb(0, 115, 255);
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
}


.labelp {
  flex: 1 0 50%;
  text-align: left;
}



.label {
  align-self: flex-start;
  text-align: left;

  margin-top: 0px;
  font-size: 18px;

  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  align-self: center;
  text-align: center;
  background-color: rgba(188, 229, 244, 0.658);
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 50px;
}

@media screen and (max-width: 768px) {}