.navbarWrap {
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  width: 100%;
  background-color: transparent;
  color: black;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.477);
  /* border-radius: 10px; */
  /* margin-left: 3px; */
  /* margin-right: 3px; */
  /* margin-top: 5px; */
  box-sizing: border-box;

}

.lang {
  display: none;
}

.logo {
  width: 50px;
  height: 50px;
  max-width: 70px;
  border-radius: 75px;
  padding: 3px;
  margin: 5px;
  border: 1px solid black;
  box-shadow: 0 0px 3px 3px rgba(255, 255, 255, 0.2);
  transition: 0.8s ease;
}

.wraph1 {
  font-size: 15px;
}

.logo:hover {
  border: 3px solid black;
  box-shadow: 5px 5px 20px 20px rgba(255, 255, 255, 0.2);
  scale: 1.1;
}

.navbarouterwrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-right: 25px;
}

.navLinks>* {
  color: black;
}

.navLinksOpen {
  display: none;
  align-items: center;
}

.navLinksOpen>* {
  color: black;
}

.logoAreaWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  color: black;
  text-decoration: none;

}

.activeLink,
.notActive,
.logoutButton {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 10px;
  font-weight: bolder;
  color: black;
  /* typical Material-UI color */
  padding: 10px 20px;
  transition: 0.4s ease;
  border: 1px solid transparent;
  font-size: 1rem;
}

.closeButton {
  background-color: transparent;
  color: black;
  width: 150px;
  font-size: 0.8rem !important;
  border: none;
  padding: 15px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  gap: 10px;
}

.closeButton:hover {
  color: black;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);

  border: 1px solid #3f51b5;

}

.notActive:hover {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  color: black;
  color: black;
  border-radius: 10px;

}

.navLogo {
  text-decoration: none;
  font-weight: bold;
}

.notActive:hover .icon {
  filter: invert(0);
}

.navLinks .activeLink {
  border-bottom: 2px solid black;
  /* typical Material-UI color */
}

.logoutButton {
  background-color: transparent;
  border-radius: 10px;

  color: black;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.logoutButton:hover {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.logoutButton:hover .icon {
  filter: invert(0);
}


.burger {
  display: none;

  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 10;
  color: black;
  font-weight: 700;
  margin-right: 25px;
  width: 75px;
  border: 2px solid transparent;
  border-top: 2px solid black;
  border-radius: 0;
  transition: 1s;
}

.burger:hover {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 5px;
}

.icon {
  width: 25px;
  height: 25px;
  max-width: 25px;
  filter: invert(1)
}


/* Burger to X toggle */
.burger.open div:nth-child(1) {
  transform: rotate(45deg);
}

.burger.open div:nth-child(2) {
  opacity: 0;

}

.burger.open div:nth-child(3) {
  transform: rotate(-45deg);

}

.slogan {
  margin: 10px;
  padding: 10px;
  font-size: 25px;
  color: black;
  font-size: 20px;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .icon {
    filter: invert(0)
  }

  .lang {
    display: flex;
    gap: 10px;
  }

  .wraph1 {
    font-size: 13px;
  }

  .logo {
    max-width: 50px;
    width: 50px;
    height: 50px;
  }

  .navLinks {
    display: none;
  }

  .burger {
    display: flex;
    font-size: 17px;
  }

  .slogan {
    /* width: 110px; */
  }

  .navLinksOpen {

    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    color: black;
    /* Let the height adjust based on the content */
    flex-grow: 1;
    /* It will take up all the remaining space */
    background-color: #fff;
    /* Change to match your design */
    top: 78px;
    /* Adjust based on your header's height */
    left: 0;
    transition: background-color 0.3s;
    padding: 2rem;
    box-sizing: border-box;
    /* Make sure padding and border are included in the element's total width and height */
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 10;
    /* Prevent horizontal scrolling */
  }

  .navLinksOpen>* {
    font-size: 1.5em;
    align-items: flex-end;
    width: 90vw;
    align-items: center;
    justify-content: center;

  }

  .activeLink,
  .notActive,
  .logoutButton {
    color: black;
  }
}