.historyMap {
  display: flex;
  gap: 5px;
  margin: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.historyItem {
  max-width: 150px;
  border-radius: 4px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: transparent;
  transition: 0.5s ease;
  box-shadow: 5px 5px 10px 2px gray;
  border: 1px solid white;

}


.historyItem:hover {
  cursor: pointer;
  border: 1px solid rgb(52, 150, 255);
  box-shadow: 5px 5px 10px 2px gray;
}



.image {
  width: 100%;
  padding: 0px;
  margin: 0px
}

.saveImage {
  padding: 10px;
  width: 100%;
  margin: 6px;
}