.effect {
  border-radius: 4px;
  padding: 10px;
  margin: 5px;
  font-size: 16px;
  background-color: white;
  border: 1px solid black;
}

.currentEffect {
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  margin: 5px;
  font-weight: bold;
  color: white;
  background-color: rgb(0, 179, 255);
  border: 1px solid white;
  box-shadow: 0px 0px 1px 2px rgb(0, 179, 255);
}

.effect:hover {
  cursor: pointer;
  background-color: rgb(0, 179, 255);
  color: white;
  border: 1px double rgb(26, 129, 232);
}

.effectImage {}