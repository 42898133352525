.wrap {
  display: flex;
  flex-direction: column;
}

.currentImage {
  width: 45vw;
  height: auto;
}


.addons {
  display: flex;
}


.button {
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  background-color: white;
  border: 1px solid black;

}

.inputImageUrl {
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
  margin: 5px;
}

.leftSection {
  flex: 1 0 26%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centerSection {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-around;
  width: 100%;
  max-width: 100vw;
}


.rightSection {
  flex: 1 0 26%;
  display: flex;
  flex-direction: column;
  align-items: center;


}

.effectUi {}