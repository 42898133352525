.currentImageCanvas {
  border: 2px solid orange;
  position: absolute;
  top: 0;
  left: 0;
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  /* z-index: -10000; */
}