.footerWrapAbstract {
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  bottom: 0px;
  /* border-top: white solid 1px; */
  color: white;
  justify-content: space-evenly;
  box-sizing: border-box;
  background-color: rgb(42, 7, 42);
  gap: 2px;
  min-height: 10vh;
}

.services {
  display: flex;
}

.footerWrap {
  flex-shrink: 0;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  /* border-top: white solid 1px; */
  color: white;
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;
  background-color: rgb(42, 7, 42);
  gap: 5px;
}

.footerLinksExt {
  border-top: 1px solid white;
  display: flex;
  flex-direction: column;
  max-width: 50vw;

}

.map {
  align-self: center;
  display: flex;
  box-sizing: border-box;
  gap: 3px;
  flex-wrap: wrap;
  justify-content: center;
}

.footerLinks {
  flex-grow: 1;
  flex-basis: 24%;
  border-top: 1px solid white;
  box-sizing: border-box;
  gap: 15px;
}

.footerLinks>label {
  padding: 3px;
  /* border-left: 1px solid white; */
  margin: 5px;
  font-weight: bolder;
}

.rights {
  text-align: left;
  word-wrap: break-word;
}

.footerLinks {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 5px;
  padding: 5px;
}

.LinkCompExt {
  color: white;
  /* typical Material-UI color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 3px;
  /* border-top: 1px solid white; */
  margin: 2px;
  display: flex;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.LinkComp {
  color: white;
  /* typical Material-UI color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 5px;
  /* border-top: 1px solid white; */
  margin: 5px;
  display: flex;
  text-align: left;
  border-bottom: 2px solid transparent;
}

.devLogo {
  width: 40px;
  height: 40px;
  max-width: 40px;

  align-self: flex-end;
}

.LinkComp:hover {
  border-bottom: 2px solid white;
  /* darker Material-UI color */
}

.LinkCompExt::before {
  content: "•";
  padding-right: 2px;
}

@media (max-width: 800px) {
  .footerWrap {
    flex-wrap: wrap;
  }

  .footerLinksExt {
    max-width: 99vw;
  }

  .map {
    justify-content: flex-start;

  }

  .services {
    flex-direction: column;
  }




  .footerLinks {
    align-items: flex-start;
  }


}