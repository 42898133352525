.wrapper {
  display: flex;
  flex-direction: column;

}

.link {
  align-self: flex-start;
  padding: 10px;
  margin: 10px;
  /* border: 1px solid red; */
}