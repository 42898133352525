.wrap {
  display: flex;
  background-color: rgba(255, 255, 255, 0.904);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  min-height: 50vh;
}


.projects {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

}

.project {
  border: 1px solid black;
  border-radius: 5px;
  padding: 15px;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
}