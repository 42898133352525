.button {
  border: 2px solid rgb(0, 157, 255);
  border-radius: 12px;
  padding: 12px;
  margin: 8px;
  font-size: 16px;
  background-color: white;
  font-weight: bold;
  box-shadow: 1px 1px 3px gray;
  transition: all 0.3s ease;
}

.button:hover {
  cursor: pointer;
  box-shadow: 0px 0px 4px rgb(0, 157, 255);

}

.label {}