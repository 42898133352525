.loginPageWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap:25px;
  /* background-image: url('../Assets/ecac8041-9646-40bd-b997-04627ae89f61.webp'); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-position: center; */
  padding: 0px;
  margin-top: 25px;
  min-height: 75vh;
}

.h1 {
  background-color: rgba(255, 255, 255, 0.477);
  margin: 0px;
  width: 100%;
}