.wrap {
  display: flex;
  background-color: rgba(255, 255, 255, 0.904);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 400px;
  margin: 0px;
  padding: 15px;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  flex-grow: 1;
}

.form>span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}

.form>button {
  align-self: center;
  margin: 15px;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  font-size: 1rem;
  color: #000;
}

.form>button:hover {
  background-color: #000;
  color: #fff;
}

.label {

  padding: 0px;
  margin: 0px;

}

.input {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1rem;
  color: #000;
  border: 1px solid #ddd;
  text-align: center;
  width: 70%;
}


@media screen and (max-width: 780px) {
  .form {
    width: 90vw;
    max-width: 100%;
  }
  .input {
    width: 60%;
  }
}