.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.icon {
  color: white;
  /* typical Material-UI color */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  padding: 5px;
  /* border-top: 1px solid white; */
  margin: 5px;
  border-bottom: 2px solid transparent;

}

.icon:hover {
  border-bottom: 2px solid white;

}

@media (max-width: 600px) {}